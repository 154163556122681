import axios from '@axios'

export default function additionalsServices() {
  const getAdditionals = async (queryParams) => {
    const res = await axios.get('/additionals', {
      params: queryParams,
    })
    return res
  }

  const create = async (queryParams) => {
    const res = await axios.post('/additional/create', queryParams)
    return res
  }

  const edit = async (queryParams) => {
    const res = await axios.post('/additional/update', queryParams)
    return res
  }

  const del = async (queryParams) => {
    const res = await axios.post('/additional/delete', queryParams)
    return res
  }

  return {
    getAdditionals,
    create,
    edit,
    del,
  }
}
